.date-live-events {
  list-style: none;
  margin: 0;
  padding: 0;
}
.date-live-events .ant-badge-status {
  overflow: hidden;
  /* white-space: nowrap; */
  width: 100%;
  text-overflow: ellipsis;
  font-size: 12px;
}
