.node-template-card {
  background-color: #e0f1fc;
  display: flex;
  flex-flow: row nowrap;
  margin: 0.5rem 0;
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 0.1rem solid #8c94ff;
  cursor: pointer;
}

.node-template-icon {
  width: 4rem;
  margin: 0.5rem;
}

.node-template-summary {

  margin: 0.5rem;
}

.node-template-display-name {

}

.node-template-type {

}

.node-template-description {

}