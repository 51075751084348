
.upcoming-live-events-container {
  display: flex;
  flex-flow: column nowrap ;
  justify-content: flex-start;
  align-content: stretch;
  margin: auto;
  padding: 0.5rem ;
  max-width: 30rem;
}

.upcoming-live-event {
  display: flex;
  flex-flow: column nowrap;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.live-event-header {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.upcoming-live-event-datetime {
  font-size: 0.8rem;
  text-align: right;
}

.upcoming-live-event-title {
  font-weight: bold;
}

.upcoming-live-event-description {
  padding-top: 0.5rem;
}

.upcoming-live-event-status-season {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-top: 0.5rem;
}