
.live-event-description {
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: pointer;
}

.live-event-datetime {
  font-family: monospace;
  font-weight: bold;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 0.3rem;
  background-color: #e6f7ff;
  padding: 0 .25rem;
  margin: .5rem 0;
}

.live-event-title {
  font-family: monospace;
  /* font-weight: bold; */
  /* font-size: 16; */
  padding: 0 .25rem;
}