
/* .youtube-live-broadcast {
  display: flex;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
  cursor: pointer;
} */
/*

.live-event-datetime {
  font-family: monospace;
  font-weight: bold;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 0.3rem;
  background-color: #e6f7ff;
  padding: 0 .25rem;
  margin: .5rem 0;
}

.live-event-title {
  font-family: monospace;
  padding: 0 .25rem;
} */

.aws-stream-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  
}

.start-aws-stream {
  cursor: pointer;
  background-color: #b63131;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  font-size: 200%;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1rem;
}

.aws-stream-status {
  background-color: #316fb6;
  margin: 1rem;
  padding: 1rem;
  text-align: center;
  font-size: 200%;
  color: #ffffff;
  font-weight: bold;
  border-radius: 1rem;
}