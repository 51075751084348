
.user-integrations-manager {
  display: flex;
  flex-flow: column nowrap;
  max-width: 40rem;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin: auto;
  background-color: white;
}

.user-integrations-manager-header {
  font-size: large;
}

.user-integration-details {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: baseline;
  border: 1px solid #b9b9b9;
  background-color: #f5fffd;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin: 0.25rem 0;
}

.integration-name {
  font-size: medium;
  font-weight: bold;
}