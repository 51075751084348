.live-event-manager {
  display: flex;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem 0.5rem;
  margin-bottom: 0.5rem;
  background-color: white;
}

.live-event-datetime {
  font-family: monospace;
  font-weight: bold;
  border-width: 0.1rem;
  border-style: solid;
  border-radius: 0.3rem;
  background-color: #e6f7ff;
  padding: 0 0.25rem;
  margin: 0.5rem 0;
}

.live-event-title {
  font-family: monospace;
  /* font-weight: bold; */
  /* font-size: 16; */
  padding: 0 0.25rem;
}

.live-story-details {
  width: 100%;
}

.live-story-status-bar {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
}

.live-story-status {
  flex: 0 1 10rem;
  text-align: center;
  border: 1px solid gray;
  border-radius: 0.5rem;
  padding: 0.5rem;
  margin: 0 0.25rem;
}

.active-status {
  opacity: 1;
  animation: fade 2s infinite;
}

.is-pre-show {
  color: #3a6dfc;
  background-color: #e8eeff;
  border-color: #3a6dfc;
}

.is-live {
  color: red;
  background-color: #fff0f0;
  border-color: red;
}

.is-complete {
  color: #3bc55e;
  background-color: #effff2;
  border-color: #3bc55e;
}

@keyframes fade {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.live-event-images-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  align-items: center;
  margin-top: 0.5rem;
}
.live-event-image {
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid #acadda;
  border-radius: 0.5rem;
  margin: 0.1rem; 
}

.selected-live-event-image {
  border: 0.2rem solid #3b3db1;
  border-radius: 0.5rem;
  background-color: #8182b4;
  cursor: not-allowed;
}

.go-live {
  display: flex;
  padding-top: 1rem;
  justify-content: center;
}

.go-live button {
  height: 5rem;
  width: 30rem;
  font-size: x-large;
}

.live-event-panel-title {
  font-weight: 700;
  font-size: larger;
}

.live-event-panel {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-top: 1rem;
  padding: 0.25rem;
  border: 0.11rem solid grey;
  border-radius: 0.5rem;
}
.live-event-panel-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.25rem;
}

.live-question-config {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.points-selector {
  align-self: center;
  padding: 0.25rem;
}

.question-type-btn {
  margin: 0.25rem;
  padding: 0.25rem;
  align-self: center;
}

.live-event-image-disabled {
  opacity: 0.5;
}

.end-story-btn {
  height: 5rem;
  width: 15rem;
  margin: 0.25rem;
  padding: 0.25rem;
  font-size: x-large;
  align-content: center;
}

.leading-node-data {
  display: flex;
  flex-flow: column nowrap;
}

.blue-votes {
  /* color: #1515c4; */
  font-size: 1.2rem;
  font-family: monospace;
  border: 0.09rem solid #73abff;
  background-color: #eaefff;
  border-radius: 25%;
  padding: 0.1rem;
}

.yellow-votes {
  /* color: #feffea; */
  font-size: 1.2rem;
  font-family: monospace;
  border: 0.09rem solid #ffd900;
  background-color: #feffea;
  border-radius: 25%;
  padding: 0.1rem;
}

.true-votes {
  font-size: 1.2rem;
  font-family: monospace;
  border: 0.09rem solid #44e93e;
  background-color: #edffea;
  border-radius: 25%;
  padding: 0.1rem;
}

.false-votes {
  font-size: 1.2rem;
  font-family: monospace;
  border: 0.09rem solid #ff0000;
  background-color: #ffeaea;
  border-radius: 25%;
  padding: 0.1rem;
}

.leading-node-data-row {
  margin: 0.25rem;
}

.template-config-card {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background-color: #d2f6ff;
  border-radius: 2rem;
  border: #3a6dfc solid 0.15rem;
  margin: 0.5rem 1rem;
  cursor: pointer;
}

.template-used {
  background-color: #f5fdff;
  border-color: #c5d4fc;
}

.template-summary {
  margin: 2rem;
}

.template-images-container {
  display: flex;
  flex-flow: row wrap;
}

.template-image {
  /* margin: 1rem; */
  flex-shrink: 1;
  flex-grow: 1;
  padding: 0.5rem;
  cursor: pointer;
  border: 0.1rem solid #acadda;
  border-radius: 0.5rem;
  margin: 0.1rem; 
}

.config-disabled {
  cursor: not-allowed;
  opacity: 50%;
}